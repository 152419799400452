import $ from 'jquery'

const activeLazyLoad = elements =>
  elements.each((_, element) => {
    const jqueryElement = $(element);
    const imagePath = jqueryElement.attr('data-src')

    $(element).attr('src', imagePath);
  })

export default activeLazyLoad;
